import React, { useState, useEffect } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { Icon } from "@iconify/react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

const ffmpeg = new FFmpeg();
// console.log("ffmpeg", ffmpeg);
const CustomAudioRecorder = ({
    onRecordingComplete,
    handleToggleRecording,
    isAudioPreview = false,
}) => {
    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
    } = useAudioRecorder({
        audioTrackConstraints: {
            noiseSuppression: true,
            echoCancellation: true,
        },
        onNotAllowedOrFound: (err) => console.error("Recording error:", err),
    });

    const [audioURL, setAudioURL] = useState(null);

    const [isFFmpegReady, setIsFFmpegReady] = useState(false);

    const loadFFmpeg = async () => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
        setIsFFmpegReady(true);
    };

    useEffect(() => {
        loadFFmpeg();
    }, []);

    const convertWebmToMp3 = async (webmBlob) => {
        if (!isFFmpegReady) return null;

        try {
            await ffmpeg.writeFile("input.webm", await fetchFile(webmBlob));
            await ffmpeg.exec(["-i", "input.webm", "output.mp3"]);
            const mp3Data = await ffmpeg.readFile("output.mp3");
            const mp3Blob = new Blob([mp3Data.buffer], { type: "audio/mp3" });
            return mp3Blob;
        } catch (error) {
            console.error("Error converting WebM to MP3:", error);
            return null;
        }
    };

    const handleRecordingComplete = async () => {
        const mp3Blob = await convertWebmToMp3(recordingBlob);
        if (mp3Blob) {
            const mp3URL = URL.createObjectURL(mp3Blob);
            setAudioURL(mp3URL);
            onRecordingComplete(mp3Blob);
        } else {
            console.error("MP3 conversion failed");
        }
    };

    useEffect(() => {
        if (recordingBlob) {
            handleRecordingComplete();
        }
    }, [recordingBlob]);

    const handleMicClick = (e) => {
        e.preventDefault();
        if (isRecording) {
            stopRecording();
            handleToggleRecording();
        } else {
            handleToggleRecording();
            startRecording();
        }
    };

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={`flex flex-col items-center rounded-full w-full ${
                isRecording && "px-8 shadow-none"
            } max-w-xl p-1 mx-auto shadow-sm`}
        >
            <div className="flex items-center gap-1 justify-between w-full">
                <button
                    onClick={handleMicClick}
                    className={`p-2 rounded-full ${
                        isRecording ? "bg-rose-500" : "bg-white dark:bg-slate-500 smooth"
                    } shadow-md hover:bg-blue-100 group transition`}
                >
                    <Icon
                        icon={`${isRecording ? "mdi:stop" : "mdi:microphone"}`}
                        width="24"
                        className={`${
                            isRecording
                                ? "text-white group-hover:text-slate-600"
                                : "text-gray-500 dark:text-slate-100"
                        } smooth`}
                    />
                </button>

                {isRecording && (
                    <div className="flex items-center w-full gap-3">
                        <div className="h-1 w-full bg-gray-100 dark:bg-gray-600 smooth rounded-full">
                            <div
                                className="h-1 bg-rose-500 rounded-full"
                                style={{ width: `${Math.min((recordingTime / 60) * 100, 100)}%` }}
                            ></div>
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                togglePauseResume();
                            }}
                            className="p-2 rounded-full bg-white shadow-md hover:bg-gray-200 transition"
                        >
                            <Icon
                                icon={isPaused ? "mdi:play" : "mdi:pause"}
                                width="20"
                                className="text-gray-500"
                            />
                        </button>
                    </div>
                )}
            </div>

            {isRecording && (
                <div className="text-gray-500 dark:test-gray-200 smooth text-xs mt-2">
                    الوقت: {recordingTime} ثانية
                </div>
            )}
            {audioURL && isAudioPreview && (
                <div className="w-full mt-4 p-2 rounded-lg bg-gray-100 flex items-center justify-between">
                    <audio controls src={audioURL} className="w-full">
                        Your browser does not support the audio element.
                    </audio>
                    <button
                        onClick={() => {
                            setAudioURL(null);
                            onRecordingComplete(null);
                        }}
                        className="text-gray-500 hover:text-red-600 p-2 rounded-full bg-white shadow-sm"
                    >
                        <Icon width="18" icon="weui:delete-on-filled" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default CustomAudioRecorder;
