import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import http from "../services/httpServices";
import page from "../services/pageServices";
import InputIcon from "../components/form/elements/InputIcon";
import {
    isMultiYear,
    loginPageColor,
    registerPageColor,
    registerPageIcon,
} from "../services/defaultSettings";
import { years } from "../services/yearSevices";
import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import auth from "../services/authServices";
import modal from "../services/modalServices";
import SigningForm from "../components/form/singingForm/SigningForm";
import InputField from "../components/form/elements/InputField";
import a from "../services/analyticsServices";

import formPicture from "../assets/applicants-form-image.jpg";
import Button from "../components/ui/Button";
import HalfPage from "../components/ui/HalfPage";
import { printFullDateTime } from "../utils/time";
import CenterIcon from "../components/ui/CenterIcon";

const CenterAppplicantsApply = ({ passedPageType = "apply" }) => {
    const [pageType, setPageType] = useState(passedPageType);

    const { center_name } = useParams();

    const [applicationStatus, setIsApplicationStatus] = useState(null);
    const [centerArabicName, setCenterArabicName] = useState("");

    const settings = {
        accepted: {
            containerClassName: "border-cyan-500 bg-cyan-500 bg-opacity-10",
            className: "bg-cyan-500",
            text: "تم القبول بنجاح",
        },
        pending: {
            containerClassName: "border-yellow-500 bg-yellow-500 bg-opacity-10",
            className: "bg-yellow-500",
            text: "قيد المراجعة",
        },
        declined: {
            containerClassName: "border-rose-500",
            className: "bg-rose-500",
            text: "مرفوض",
        },
        seat: {
            containerClassName: "border-cyan-500 bg-cyan-500 bg-opacity-10",
            className: "bg-cyan-500",
            text: "انت من الحاجزين ضمن المقاعد الأساسية في المجموعة !",
        },
        extra_seat: {
            containerClassName: "border-yellow-500 bg-yellow-500 bg-opacity-10",
            className: "bg-yellow-500",
            text: "انت من اللي حاجزين ضمن قايمة الإنتظار في المجموعة !",
        },
        over_seat: {
            containerClassName: "border-rose-500",
            className: "bg-rose-500",
            text: "انت ليك اقصى اولوية بعد قايمة الانتظار في المجموعه !",
        },
    };

    useEffect(() => {
        setPageType(passedPageType);
    }, [passedPageType]);
    useEffect(() => {
        setUser({ ...user, center_name });
    }, [center_name]);

    const [user, setUser] = useState({
        first_name: "",
        second_name: "",
        third_name: "",
        last_name: "",
        phone: "",
        father_phone: "",
        national_id_copy: "",
        national_id_number: "",
        city: "",
        gender: "",
        center_name: center_name,
    });
    const [errors, setErrors] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const { setAccountRequest } = useContext(AuthContext);

    const [cities, setCities] = useState([]);

    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        let fields = [];
        if (passedPageType === "apply") {
            fields = [
                ...fields,
                {
                    id: "first_name",
                    placeholder: `الاسم الأول`,
                    icon: <InputIcon icon="icon-park-solid:edit-name" />,
                },
                {
                    id: "second_name",
                    placeholder: `الاسم الثاني`,
                    icon: <InputIcon icon="icon-park-solid:edit-name" />,
                },
                {
                    id: "third_name",
                    placeholder: `الاسم الثالث`,
                    icon: <InputIcon icon="icon-park-solid:edit-name" />,
                },
                {
                    id: "last_name",
                    placeholder: `الاسم الأخير`,
                    icon: <InputIcon icon="icon-park-solid:edit-name" />,
                },
            ];
        }

        fields = [
            ...fields,
            {
                id: "phone",
                placeholder: "رقم الهاتف",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
            {
                id: "father_phone",
                placeholder: "رقم هاتف ولي الأمر",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
        ];
        if (pageType === "apply") {
            fields = [
                ...fields,
                {
                    id: "national_id_copy",
                    placeholder: "ارفع شهادة ميلادك / بطاقتك",
                    type: "file",
                    icon: <InputIcon icon="icon-park-twotone:id-card" />,

                    className: "lg:col-span-2",
                },
            ];
        }
        fields = [
            ...fields,
            {
                id: "national_id_number",
                placeholder: "الرقم القومي",
                icon: <InputIcon icon="solar:user-id-broken" />,
                className: "lg:col-span-2",
            },
        ];
        if (pageType === "apply") {
            fields = [
                ...fields,
                {
                    id: "city",
                    placeholder: "المدينة",
                    type: "select",
                    options: cities,
                    className: "lg:col-span-2",
                },
                {
                    id: "gender",
                    placeholder: "النوع",
                    type: "select",
                    options: [
                        { value: "male", text: "ذكر" },
                        { value: "female", text: "انثى" },
                    ],
                    className: "lg:col-span-2",
                },
            ];
        }

        setInputFields(fields);
    }, [cities, pageType]);

    const handleSubmit = (e) => {
        if (pageType === "apply") {
            handleFormSubmit(e, setIsLoading, setErrors, sendApplication);
        } else {
            handleFormSubmit(e, setIsLoading, setErrors, checkStatus);
        }
    };

    const navigate = useNavigate();

    const { state } = useLocation();

    const redirect = (certainPath = false) => {
        if (certainPath) {
            navigate(certainPath);
        } else {
            if (!state) {
                navigate("/home");
            } else {
                navigate(state.prevPath);
            }
        }
    };

    const getCities = async () => {
        try {
            const { data } = await http.get(`/api/center_applicants/get_data/${center_name}`);
            setCities(data.cities);
            setCenterArabicName(data.center);
            user["city"] = data.cities[0]["value"];
            setUser(user);
        } catch (error) {
            if (error.response.status == 404) {
                redirect("/");
            }
        }
    };

    useEffect(() => {
        getCities();
    }, [center_name]);

    const sendApplication = async () => {
        try {
            await http.get("/sanctum/csrf-cookie");
            const visitorVisitId = a.getVisitorVisit();
            const toSendUser = { ...user, visitor_visit_id: visitorVisitId };
            toSendUser["phone"] = parseInt(user["phone"]);
            toSendUser["father_phone"] = parseInt(user["father_phone"]);

            const formData = getFormData(toSendUser);

            const result = await http.post("api/center_applicants", formData);
            // auth.authenticates(result.data.token, result.data.user);
            // setAccountRequest({
            //     ...result.data.user,
            // });

            setIsLoading(false);
            modal.message({
                title: `أهلا ${user.first_name}
                تم ارسال طلب الحجز الخاص بك و 
                هيتم مراجعة الطلب و التواصل معاك في خلال ٤٨ ساعة!`,
                text: "اضغط حسنًا للإستمرار",
                callback: () => {
                    setAccountRequest({
                        ...result.data.user,
                    });
                    setIsApplicationStatus(result.data);
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };
    const checkStatus = async () => {
        try {
            await http.get("/sanctum/csrf-cookie");
            const visitorVisitId = a.getVisitorVisit();
            const toSendUser = { ...user, visitor_visit_id: visitorVisitId };
            toSendUser["phone"] = parseInt(user["phone"]);
            toSendUser["father_phone"] = parseInt(user["father_phone"]);

            const formData = getFormData(toSendUser);

            const result = await http.post("api/center_applicants/check-status", formData);
            // auth.authenticates(result.data.token, result.data.user);
            // setAccountRequest({
            //     ...result.data.user,
            // });

            setIsLoading(false);
            setIsApplicationStatus(result.data);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <>
            {applicationStatus ? (
                <HalfPage picture={formPicture}>
                    <div className={`w-4/5 min-h-[80%] p-10 lg:px-10 flex-col`}>
                        <div className="font-big flex-center-both pb-10">
                            <div className="flex-center-both space-x-3 space-x-reverse border-b-2 border-secondary-container smooth clr-text-primary">
                                <CenterIcon
                                    className="text-cyan-500"
                                    icon={"hugeicons:university"}
                                />
                                <div className="">سنتر</div>
                                <div className="text-cyan-500 font-w-bold">{centerArabicName} </div>
                            </div>
                        </div>
                        <div
                            className={`flex-center-both h-full border-2 rounded-md p-2 ${
                                settings[applicationStatus.application_status]["containerClassName"]
                            }`}
                        >
                            <div className="pb-10 space-y-10 flex-center-both flex-col">
                                <div className="font-big font-w-bold space-x-2 space-x-reverse">
                                    <span className="font-h3">أهلًا </span>
                                    <span className="underline">
                                        {applicationStatus.first_name}
                                    </span>
                                </div>
                                <div className=" space-y-4">
                                    <div
                                        className={`rounded-md text-white px-4 py-1 ${
                                            settings[applicationStatus.seats_status]["className"]
                                        }`}
                                    >
                                        {settings[applicationStatus.seats_status]["text"]}
                                    </div>
                                    <div className="flex-center-both space-x-2 space-x-reverse">
                                        <span className="font-w-bold">حالة طلب الحجز : </span>
                                        <div
                                            className={`px-3 pb-0.5 text-slate-50 rounded-md font-w-bold ${
                                                settings[applicationStatus.application_status][
                                                    "className"
                                                ]
                                            }`}
                                        >
                                            {settings[applicationStatus.application_status]["text"]}
                                        </div>
                                    </div>
                                </div>
                                {applicationStatus.application_status === "declined" ? (
                                    <div className="space-y-2 flex-center-both flex-col">
                                        <div>سبب الرفض :</div>
                                        <div className="bg-rose-500 p-5 rounded-md shadow-md">
                                            {applicationStatus.message}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {applicationStatus.application_status === "accepted" ? (
                                    <>
                                        <div className="space-y-2 flex-center-both flex-col">
                                            <div>السريال الخاص بيك :</div>
                                            <div className="bg-cyan-800 px-4 py-1 rounded-md shadow-md text-white font-w-bold font-h2">
                                                {applicationStatus.serial_number}
                                                {console.log(applicationStatus)}
                                            </div>
                                        </div>
                                        <div className="space-y-2 flex-center-both flex-col">
                                            <div>البين كود الخاص بيك :</div>
                                            <div className="bg-cyan-800 px-4 py-1 rounded-md shadow-md text-white font-w-bold font-h2">
                                                {applicationStatus.pin_code}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                <div className="flex-center-both space-x-2 space-x-reverse">
                                    <div className="">تاريخ انشاء طلب الحجز:</div>
                                    <div className="font-w-bold">
                                        {printFullDateTime(
                                            applicationStatus.created_at ?? new Date.now()
                                        )}
                                    </div>
                                </div>
                                {applicationStatus.application_status == "pending" ? (
                                    <div className="flex-center-both space-x-2 space-x-reverse">
                                        سيتم مراجعة الطلب الخاص بيك و التواصل معك في خلال ٤٨ ساعة من
                                        وقت انشاء طلبك
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </HalfPage>
            ) : (
                <SigningForm
                    extraHeader={
                        <div className="font-big flex-center-both">
                            <div className="flex-center-both space-x-3 space-x-reverse border-b-2 border-secondary-container smooth clr-text-primary">
                                <CenterIcon
                                    className="text-cyan-500"
                                    icon={"hugeicons:university"}
                                />
                                <div className="">سنتر</div>
                                <div className="text-cyan-500 font-w-bold">{centerArabicName} </div>
                            </div>
                        </div>
                    }
                    backgroundPosition={"center center"}
                    className="space-y-6"
                    headTitle={
                        pageType === "apply"
                            ? "طلب حجز مكانك في السنتر !"
                            : "استفسر عن الحجز بتاعك دلوقتي !"
                    }
                    headIcon={
                        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMTQgMTQiPjxnIGZpbGw9Im5vbmUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0uNzA3IDEzLjQzOGgxMi43NU0zLjg2IDUuNjg3bDMuMjIyLTIuNTNsMy4yMjIgMi41M000LjM1IDcuODEzaDUuNDY0djUuNjI1SDQuMzVWNy44MTFaTTMuMjM4IDUuNjg4aDcuNjg4djIuMTIxSDMuMjM4ek0xLjYxOCA3LjgxdjUuNjI4TTEyLjU0NiA3LjgxdjUuNjI1TS43MDcgNy44MWgxMi43NU03LjA4MiAzLjE1NlYuNjc0bTAgMGgxLjI1Ii8+PHBhdGggZD0iTTYuMDk1IDExLjIwM2EuOTMuOTMgMCAwIDEgLjkzMS0uOTN2MGEuOTMuOTMgMCAwIDEgLjkzMS45M3YyLjIzNUg2LjA5NXoiLz48L2c+PC9zdmc+"
                    }
                    picture={formPicture}
                    description={
                        "اتأكد انك دخلت بياناتك الاول عشان يكون ليك الأولوية في الحجز في السنتر !"
                    }
                    onSubmit={handleSubmit}
                    fields={
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-6">
                            {inputFields.map((input, index) => {
                                return (
                                    <InputField
                                        key={index}
                                        onChange={handleInputChange}
                                        data={user}
                                        setData={setUser}
                                        errors={errors}
                                        {...input}
                                    />
                                );
                            })}
                        </div>
                    }
                    color={registerPageColor}
                    secondaryColor={loginPageColor}
                    buttonTitle={pageType === "apply" ? "طلب حجز السنتر!" : "شوف حالة الحجز بتاعك!"}
                    isLoading={isLoading}
                    altLink={`/applicants/${center_name}/${
                        pageType == "apply" ? "status_check" : "apply"
                    }`}
                    alt={
                        pageType === "apply"
                            ? "عاوز تشوف طلب الحجز بتاعك ؟"
                            : "عاوز تعمل حجز جديد ؟"
                    }
                    altColored={
                        pageType === "apply"
                            ? "استعلم عن طلب الحجز دلوقتي !"
                            : "سجل طلب حجزك دلوقتي !"
                    }
                />
            )}
        </>
    );
};

export default CenterAppplicantsApply;
