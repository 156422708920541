import React, { useContext, useEffect, useState } from "react";
import Form from "../components/form/elements/Form";
import InputField from "../components/form/elements/InputField";
import Button from "../components/ui/Button";
import auth from "../services/authServices";
import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";
import http from "../services/httpServices";
import AuthContext from "../context/AuthContext";
import CenterIcon from "../components/ui/CenterIcon";
import AudioPlayer from "../components/ui/AudioPlayer";
import {
    isCommunityVoiceRecordingEnabled,
    isWhatsAppAudioPlayerStyle,
} from "../services/defaultSettings";
import ShowImage from "../components/form/elements/ShowImage";
import { Icon } from "@iconify/react/dist/iconify";

const renderAudioPlayer = (setData, data) => {
    return (
        <div className="w-full p-2 rounded-lg smooth flex items-center justify-between space-x-reverse space-x-2">
            {isWhatsAppAudioPlayerStyle ? (
                <AudioPlayer setData={setData} audioSrc={URL.createObjectURL(data.audio_source)} />
            ) : (
                <>
                    <audio
                        controls
                        src={URL.createObjectURL(data.audio_source)}
                        className="w-full bg-gray-100 dark:bg-gray-800 smooth"
                    >
                        Your browser does not support the audio element.
                    </audio>
                    <button
                        onClick={() => {
                            setData((prevData) => ({
                                ...prevData,
                                audio_source: "",
                            }));
                        }}
                        className="text-red-500 smooth hover:text-red-800 smooth p-2 rounded-full bg-gray-300 dark:bg-gray-700"
                    >
                        <Icon width="18" icon="weui:delete-on-filled" className="w-6 h-6" />
                    </button>
                </>
            )}
        </div>
    );
};
const initialState = {
    description: "",
    file: "",
};

const CommunityCreateCommentForm = ({ category, posts, setPosts, post, display = "default" }) => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isShownAddComment, setIsShownAddComment] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const { user, adminToken } = useContext(AuthContext);

    const handleToggleRecording = () => setIsRecording(!isRecording);
    useEffect(() => {
        if (isRecording) {
            setData((prevData) => ({ ...prevData, audio_source: "" }));
        }
    }, [isRecording]);
    const handleSubmit = async (e) => {
        // if (isRecording) {
        //     e.preventDefault();
        //     return;
        // }
        handleFormSubmit(e, setIsLoading, setErrors, async () => {
            setIsLoading(true);
            const token = auth.getToken();
            let config = auth.getAuthConfig(token);
            const adminToken = auth.getAdminToken();
            let toSendData = data;
            if (adminToken) {
                config = auth.getAuthConfig(adminToken);
                toSendData = { ...toSendData, user_submitting_by_email: user.email };
            }
            try {
                const formData = getFormData(toSendData);
                let { data: response } = await http.post(
                    `/api/community_categories/${category["id"]}/posts/${post["id"]}/comments`,
                    formData,
                    config
                );
                response = {
                    ...response,
                    user: { full_name: user.full_name },
                };
                setPosts(
                    posts.map((item) => {
                        if (item.id !== post.id) {
                            return item;
                        }
                        item.community_comments_count++;
                        item.community_comments = [response, ...item.community_comments];
                        return item;
                    })
                );
                setIsLoading(false);
                setIsShownAddComment(false);
                setData(initialState);
            } catch ({ response }) {
                handleFormErrors(response, setIsLoading, setErrors, false);
            }
        });
    };
    return (
        <>
            {(category.is_closed || post.is_closed) && !adminToken ? (
                ""
            ) : isShownAddComment ? (
                <div className="pt-4 flex-center-both">
                    <Form
                        className={`flex-center-both flex-col gap-10 ${
                            display === "minimal" ? "flex-col max-w-xl gap-3" : " max-w-md"
                        } w-full max-w-md mx-auto p-5 rounded-md clr-text-primary smooth shadow-md bg-cyan-500 bg-opacity-20 relative`}
                        onSubmit={handleSubmit}
                    >
                        <div
                            className="absolute top-0 right-0 p-3 cursor-pointer"
                            onClick={() => setIsShownAddComment(false)}
                        >
                            <CenterIcon icon="tabler:x" />
                        </div>
                        {display === "default" ? (
                            <div className="w-full space-y-4">
                                <InputField
                                    id="description"
                                    type="textarea"
                                    placeholder="اكتب تعليق"
                                    setData={setData}
                                    data={data}
                                    errors={errors}
                                    onChange={handleInputChange}
                                />

                                <div className="flex-center-both w-full gap-3  items-center">
                                    {isCommunityVoiceRecordingEnabled && adminToken && (
                                        <InputField
                                            id="audio_source"
                                            type="custom_audio"
                                            placeholder="تعليق صوتي"
                                            setData={setData}
                                            data={data}
                                            errors={errors}
                                            onChange={handleInputChange}
                                            handleToggleRecording={handleToggleRecording}
                                        />
                                    )}
                                    {data.audio_source && isCommunityVoiceRecordingEnabled ? (
                                        renderAudioPlayer(setData, data)
                                    ) : (
                                        <>
                                            <div className="">هل تريد تسجيل ريكورد؟</div>
                                        </>
                                    )}
                                </div>
                                {/* <div className="">هل تريد ارفاق صورة؟</div> */}
                                <InputField
                                    id="image"
                                    type="file"
                                    placeholder="ارفق صورة"
                                    isRequired={false}
                                    inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                    setData={setData}
                                    data={data}
                                    errors={errors}
                                    onChange={handleInputChange}
                                    className="w-full"
                                    withRotationControl
                                />
                            </div>
                        ) : display === "minimal" ? (
                            <>
                                <div className="flex w-full items-center space-x-3 space-x-reverse p-2 bg-[#62B6CB] dark:bg-[#296A7A] smooth rounded-lg">
                                    {isCommunityVoiceRecordingEnabled && adminToken && (
                                        <div
                                            className={`flex-shrink-0 ${
                                                isRecording ? "w-full" : ""
                                            }`}
                                        >
                                            <InputField
                                                id="audio_source"
                                                type="custom_audio"
                                                placeholder="تعليق صوتي"
                                                setData={setData}
                                                data={data}
                                                errors={errors}
                                                onChange={handleInputChange}
                                                handleToggleRecording={handleToggleRecording}
                                            />
                                        </div>
                                    )}

                                    {/* Text Input Field in the Center */}
                                    {!isRecording && (
                                        <div className="flex w-full items-center space-x-3 p-2 bg-[#62B6CB] dark:bg-[#296A7A] smooth rounded-lg">
                                            <InputField
                                                id="description"
                                                type="textarea"
                                                isCommunityCommentForm={true}
                                                placeholder="اكتب تعليق"
                                                setData={setData}
                                                data={data}
                                                errors={errors}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 rounded-lg"
                                            />
                                            <InputField
                                                id="image"
                                                type="file"
                                                isCommunityCommentForm={true}
                                                inputProps={{
                                                    accept: "image/png, image/gif, image/jpeg",
                                                }}
                                                setData={setData}
                                                data={data}
                                                isRequired={false}
                                                errors={errors}
                                                onChange={handleInputChange}
                                                className="w-8 h-8 cursor-pointer text-gray-500"
                                                withRotationControl
                                            />
                                        </div>
                                    )}
                                </div>
                                {data.audio_source &&
                                    isCommunityVoiceRecordingEnabled &&
                                    renderAudioPlayer(setData, data)}
                                {data.image && (
                                    <div className="relative">
                                        <ShowImage file={data.image} />
                                        <button
                                            onClick={() => {
                                                setData((prevData) => ({ ...prevData, image: "" }));
                                            }}
                                            className="text-red-500 absolute -top-1 -left-3 smooth hover:text-red-800 p-2 rounded-full bg-slate-50 dark:bg-slate-700"
                                        >
                                            <Icon
                                                width="18"
                                                icon="icon-park-solid:close-one"
                                                className="w-6 h-6"
                                            />
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            ""
                        )}
                        <div className="flex-center-both">
                            <Button color="cyan" isLoading={isLoading}>
                                {"ارسال التعليق"}
                            </Button>
                        </div>
                    </Form>
                </div>
            ) : (
                <div className="pt-3  flex flex-row space-x-2 space-x-reverse w-full flex-center-both">
                    <div
                        className="w-full rounded-lg bg-cyan-400 bg-opacity-40 hover:bg-opacity-60 smooth clr-text-secondary py-1 px-4 font-w-bold cursor-pointer font-smaller max-w-sm"
                        onClick={() => setIsShownAddComment(!isShownAddComment)}
                    >
                        شاركنا بتعليقك هنا !
                    </div>
                </div>
                // <div className="flex-center-both">
                //     <Button color="cyan" >
                //         اترك تعليقًا !
                //     </Button>
                // </div>
            )}
        </>
    );
};

export default CommunityCreateCommentForm;
