import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "bassathalkadmin-panel";
export const teachersubadminPath = "bassathalk-teacher-admin";
export const domainName = "bassathalk";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = true;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "blue";
export const headerSectionColor = "sky";
export const navbarIconColor = "text-sky-500";
export const navbarDisclosureColor = "sky";

export const progressBarColor = "sky";
export const loadingBarColor = "sky";

export const isBunny = true;

export const isPlatformSubscription = true;
export const isTimedManualSubscription = true;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "blue";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "sky";

export const isAnalytics = true;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = true;

export const isAccountCreationRequest = true;
export const isApplicants = true;

export const showExamStatisticsTable = true;

export const isAdminToAdminNote = true;
export const isAdminToAdminTags = true;
export const isAdminAbleToChangeUsersYear = true;

export const isOtpEnabled = true;

export const isWalletEnabled = true;

export const isStudyPlannerEnabled = true;

export const isOnlySubscribeFromWallet = true;

export const isCenterUsers = true;

export const isCenterUserExamResults = true;

export const isForgetPassword = true;

export const isAdminUsers = true;

export const isModeratorUsers = true;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = true;
export const isChargeInsertAuto = true;
export const isInsertAutoTypes = true;

export const isCourseRenamedToMonthlySubscriptions = true;

export const isCommunity = true;
export const isCommunityModeratorsAnalytics = true;
export const isCommunityTags = true;
export const isCommunityParallelReviewCommunityPosts = true;

export const isCustomUserExamLimit = true;
export const isCustomUserVideoLimit = true;

export const isCenterApi = true;

export const isGlobalNotifications = true;

export const isLoginAsUser = true;

export const isEssayQuestions = true;

export const isAdminCourseStatistics = true;

export const isSearch = true;

export const isPinnedCourse = true;

export const isUnassignedSubscription = true;

export const isStoreLocatorAvailable = false;

export const isYoutubeVideoButton = true;

export const isAddCenterUser = true;

export const isJoinUs = true;

export const isSessionReservation = true;

export const isCourseToCourseRelations = true;

export const isManuallyDisablingAllVideos = false;
export const isManuallyDisablingAllExams = false;
export const isManuallyDisablingTempNotifications = true;
export const isManuallyDisablingUserAnalytics = false;
export const isManullayDisablingCenterExamResultToken = false;
export const isManullayDisablingPrepaidStore = false;
export const isManullayDisablingUnassignedSubscriptions = false;
export const isManullayDisablingNotifications = true;
export const isManuallyDisablingGlobalNotifications = false;

export const isManuallyDisablingFetchUserData = false;
export const isFinishedUnfinishedExamButton = true;

export const isCommunityVoiceRecordingEnabled = true;
export const isWhatsAppAudioPlayerStyle = true;
