import { Switch } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import CenterIcon from "../../ui/CenterIcon";

import FlexRowReverse from "../../ui/FlexRowReverse";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { getOptionTexts, remapOptionsTextToLabel } from "../../../utils/objects";
import Btns from "./Btns";

import ShowImage from "./ShowImage";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AudioRecorder } from "react-audio-voice-recorder";
import CustomAudioRecorder from "../../ui/CustomAudioRecorder";

const animatedComponents = makeAnimated();
const InputField = ({
    id = "",
    placeholder = "",
    icon = "",
    value = "",
    type = "text",
    onChange,
    className = "",
    togglePassword = false,
    options = null,
    error = "",
    isDisabled = false,
    data = {},
    setData = null,
    errors = {},
    multiple = false,
    visible = true,
    isRequired = true,
    returnInputRef = () => null,
    isIntValues = false,
    inputProps = {},
    autoFocus = false,
    isOnlyDate = false,
    filterDateFunction = null,
    beforePercentage = "",
    afterPercentage = "",
    maxLength = 524288,
    datePickerPerWeek = false,
    isCommunityCommentForm = false,
    handleToggleRecording,
    withRotationControl = false,
    ...props
}) => {
    const inputRef = useRef(null);
    const [audioURL, setAudioURL] = useState(null);

    useEffect(() => {
        returnInputRef(inputRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (
        (id === "password" || id === "password_confirmation") &&
        type === "text" &&
        !togglePassword
    ) {
        type = "password";
    }
    if (type === "br") {
        <div className={`${className ? className : "px-5"}`}>
            <div className="h-1 bg-secondary-container smooth rounded-md w-full"></div>
        </div>;
    } else if (type === "btns") {
        return (
            <>
                <div className={`${className}`}>
                    <Btns data={data} setData={setData} {...props} />
                </div>
            </>
        );
    } else if (type === "html") {
        return (
            <>
                <div className={`${className}`}>
                    <div>{placeholder}</div>
                </div>
            </>
        );
    } else if (type === "datetime") {
        return (
            <>
                <div className={`${className} ${visible ? "" : "hidden"}`}>
                    <span>{placeholder}</span>
                    <div
                        className={`form-reg__group ${errors[id] && "error"} ${
                            isDisabled && "disabled"
                        } `}
                        {...props}
                    >
                        <input
                            className={`smooth z-10`}
                            onChange={!isDisabled ? (e) => onChange(e, id, data, setData) : null}
                            type="datetime-local"
                            required={isRequired}
                            id={id}
                            value={data[id]}
                            disabled={isDisabled}
                        />
                        <span className="bg"></span>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                    </div>
                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    } else if (type === "datepicker") {
        const today = new Date();
        const sevenDaysFromToday = new Date();
        sevenDaysFromToday.setDate(today.getDate() + 7);

        return (
            <>
                <div className={`${className} ${visible ? "" : "hidden"}`}>
                    <span>{placeholder}</span>
                    <div
                        className={`form-reg__group ${errors[id] && "error"} ${
                            isDisabled && "disabled"
                        } `}
                        {...props}
                    >
                        {/* <input
                            className={`smooth z-10`}
                            onChange={!isDisabled ? (e) => onChange(e, id, data, setData) : null}
                            type={isOnlyDate ? "date" : "datetime-local"}
                            required={isRequired}
                            id={id}
                            value={data[id]}
                            disabled={isDisabled}
                        /> */}
                        <DatePicker
                            id={id}
                            className={`smooth z-10 w-full !cursor-pointer`}
                            required={isRequired}
                            selected={data[id]}
                            placeholderText={"MM/DD/YYYY"}
                            value={data[id]}
                            onChange={
                                !isDisabled
                                    ? (date) => {
                                          date =
                                              new Date(date).getFullYear() +
                                              "-" +
                                              (new Date(date).getMonth() + 1 + "").padStart(2, 0) +
                                              "-" +
                                              (new Date(date).getDate() + "").padStart(2, 0);

                                          onChange(date, id, data, setData, "date_picker");
                                      }
                                    : null
                            }
                            disabled={isDisabled}
                            filterDate={filterDateFunction}
                            minDate={datePickerPerWeek ? today : null}
                            maxDate={datePickerPerWeek ? sevenDaysFromToday : null}
                        />

                        <span className="bg"></span>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                    </div>
                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    } else if (type === "file") {
        return (
            <>
                <div className={`relative ${className}`}>
                    <div
                        className={`${isCommunityCommentForm ? "" : "form-reg__group relative"}  ${
                            errors[id] && "error"
                        } ${isDisabled && "disabled"} `}
                        {...props}
                    >
                        <div
                            className={`${
                                isCommunityCommentForm
                                    ? ""
                                    : "file-upload-wrapper relative h-[50px] w-full flex-center-both rounded-lg smooth hover:border-opacity-20 hover:border-cyan-500 after:rounded-l-md before:rounded-r-md"
                            }`}
                            data-text={
                                data[id] && !isCommunityCommentForm
                                    ? data[id].name
                                    : placeholder
                                    ? placeholder
                                    : "ارفع الصورة"
                            }
                        >
                            <input
                                id={id}
                                type="file"
                                className={`${
                                    isCommunityCommentForm
                                        ? "hidden"
                                        : " smooth z-10 file-upload-field"
                                }`}
                                required={isRequired}
                                disabled={isDisabled}
                                // defaultValue={data[id]}
                                onChange={
                                    !isDisabled
                                        ? (e) => {
                                              //   setfileDataText(
                                              //       e.currentTarget.value.split("\\").slice(-1)
                                              //   );
                                              onChange(e, id, data, setData);
                                          }
                                        : null
                                }
                                {...inputProps}
                            />
                        </div>
                        {isCommunityCommentForm && (
                            <label
                                htmlFor={id}
                                className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 cursor-pointer text-gray-500 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                            >
                                <Icon icon={"solar:gallery-add-bold"} className="w-5 h-5" />
                            </label>
                        )}
                    </div>

                    {data[id] && !isCommunityCommentForm ? (
                        <ShowImage withRotationControl={withRotationControl} file={data[id]} />
                    ) : (
                        ""
                    )}
                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className={`w-full h-full inset-0 bg-inner-container z-10 opacity-90 smooth ${
                            isDisabled ? "absolute" : "hidden"
                        } `}
                    ></div>
                </div>
            </>
        );
    } else if (type === "date_range") {
        return (
            <>
                <div className={`${className}`}>
                    <div
                        className={`form-reg__group ${errors[id] && "error"} ${
                            isDisabled && "disabled"
                        } `}
                        {...props}
                    >
                        <Flatpickr
                            id={id}
                            value={
                                data[id].length > 1
                                    ? [new Date(data[id][0]), new Date(data[id][1])]
                                    : []
                            }
                            placeholder={placeholder}
                            className="px-2 py-4 border-b-[1px] border-b-[#6b7280] outline-none text-end"
                            style={{ direction: "ltr" }}
                            onChange={(date) => {
                                date = date.map((item) => {
                                    return (
                                        new Date(item).getFullYear() +
                                        "-" +
                                        (new Date(item).getMonth() + 1 + "").padStart(2, 0) +
                                        "-" +
                                        (new Date(item).getDate() + "").padStart(2, 0)
                                    );
                                });
                                if (date.length > 1)
                                    onChange(date, id, data, setData, "date_range");
                            }}
                            options={{
                                mode: "range",
                            }}
                        />
                    </div>
                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className={`w-full h-full inset-0 bg-inner-container z-10 opacity-90 smooth ${
                            isDisabled ? "absolute" : "hidden"
                        } `}
                    ></div>
                </div>
            </>
        );
    } else if (type === "textarea") {
        return (
            <>
                <div className={`${className}`}>
                    <div
                        className={` ${errors[id] && "error"} ${isDisabled && "disabled"} ${
                            isCommunityCommentForm
                                ? "flex items-center space-x-2 p-2 bg-[#85C6D6]  smooth rounded-full shadow-md"
                                : "form-reg__group"
                        }`}
                        {...props}
                    >
                        <textarea
                            className={`
                                    ${
                                        isCommunityCommentForm
                                            ? `${
                                                  errors[id] ? "border-red-500" : "border-none"
                                              } w-full h-10 px-4 py-2 text-gray-800 rounded-full focus:outline-none smooth bg-[#85C6D6] resize-none text-sm placeholder-gray-500 dark:text-white`
                                            : "smooth z-10"
                                    }
                                    `}
                            onChange={!isDisabled ? (e) => onChange(e, id, data, setData) : null}
                            type={type}
                            required={isRequired}
                            id={id}
                            value={data[id] ?? ""}
                            disabled={isDisabled}
                            placeholder={isCommunityCommentForm ? ",,," : ""}
                            ref={inputRef}
                            autoFocus={autoFocus}
                        >
                            {data[id]}
                        </textarea>
                        <span className="bg"></span>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        {!isDisabled && !isCommunityCommentForm && (
                            <label>
                                <FlexRowReverse>
                                    {icon}
                                    <span>{placeholder}</span>
                                </FlexRowReverse>
                            </label>
                        )}
                    </div>
                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    } else if (type === "switch") {
        return (
            <div className={`for-reg__group flex justify-between relative ${className}`}>
                <div className="font-h3 flex-center-both smooth clr-text-primary">
                    <span>{placeholder}</span>
                </div>
                <Switch
                    checked={!isDisabled && value ? value : data[id]}
                    disabled={isDisabled}
                    onChange={(value) =>
                        onChange(
                            {
                                currentTarget: {
                                    value: !isDisabled && value ? 1 : 0,
                                    type: "checkbox",
                                    id,
                                },
                            },
                            id,
                            data,
                            setData
                        )
                    }
                    className={`${
                        (!isDisabled && value ? value : data[id]) ? "bg-cyan-900" : "bg-slate-200"
                    }  relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ar`}
                >
                    <span className="sr-only">Enable notifications</span>
                    <span
                        className={`${
                            (!isDisabled && value ? value : data[id])
                                ? "-translate-x-9"
                                : "translate-x-0"
                        }  pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out flex-center-both`}
                    >
                        <CenterIcon
                            className={`text-slate-90 font-h1 text-cyan-600 transition duration-200 ${
                                (!isDisabled && value ? value : data[id])
                                    ? "opacity-100"
                                    : "opacity-0"
                            }`}
                            icon="typcn:tick"
                        />
                    </span>
                </Switch>
                <div
                    className={`w-full h-full inset-0 bg-inner-container z-10 opacity-90 smooth rounded-md ${
                        isDisabled ? "absolute" : "hidden"
                    } `}
                ></div>
            </div>
        );
    } else if (type === "range") {
        return (
            <div className={`${className}`}>
                <div
                    className={`for-reg__group ${errors[id] && "error"} ${
                        isDisabled && "disabled"
                    } `}
                    {...props}
                >
                    <div className="">
                        <label htmlFor={id} className="block font-bold">
                            {placeholder}
                        </label>
                        <input
                            type={type}
                            id={id}
                            className="w-full smooth range-slider"
                            style={{
                                "--value": `${data[id]}%`,
                            }}
                            min="0"
                            max="100"
                            value={data[id] ?? ""}
                            required={isRequired}
                            onChange={!isDisabled ? (e) => onChange(e, id, data, setData) : null}
                            ref={inputRef}
                            autoFocus={autoFocus}
                        />
                    </div>
                    <div className="flex justify-between clr-text-secondary smooth font-small">
                        <span>
                            {beforePercentage + data[id] ?? 0} % {afterPercentage}
                        </span>
                        <span>100%</span>
                    </div>
                </div>
                {errors[id] && (
                    <div className="pb-3 pt-1">
                        <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                            {typeof errors[id] === "object" ? (
                                <div>- {errors[id][0]}</div>
                            ) : (
                                <div>- {errors[id]}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    } else if (type === "select") {
        let selectValue;

        // const selectStyles = {
        //     container: (base, state) => ({
        //         ...base,
        //         zIndex: "999",
        //     }),
        // };

        const optionsTexts = getOptionTexts(options);
        if (multiple) {
            selectValue = data[id].map((value) => {
                return { value, label: optionsTexts[value] };
            });
        } else {
            if (data[id]) {
                selectValue = { value: data[id], label: optionsTexts[data[id]] };
            }
        }
        return (
            <div className={`react-select__outer-container relative ${className}`}>
                <Select
                    id={id}
                    key={id}
                    value={selectValue}
                    placeholder={placeholder}
                    defaultValue={placeholder}
                    options={remapOptionsTextToLabel(options)}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                        }),
                        option: (base) => ({
                            ...base,
                            // background: "var(--color-primary-container)",
                            borderRadius: "5px",
                            marginBottom: "3px",
                            marginTop: "3px",
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral0: "var(--color-primary-container)",
                            primary: "var(--color-text-primary)",
                            primary25: "var(--color-secondary-container)",
                            primary50: "var(--color-third-container)",
                        },
                    })}
                    // onChange={!isDisabled ? (e) => console.log(e) : null}
                    onChange={
                        !isDisabled
                            ? (value) =>
                                  onChange(
                                      {
                                          currentTarget: {
                                              id,
                                              type,
                                              value,
                                              isMulti: multiple,
                                          },
                                      },
                                      id,
                                      data,
                                      setData
                                  )
                            : null
                    }
                    isDisabled={isDisabled}
                    isMulti={multiple}
                    // loadOptions={options}
                    required={isRequired}
                    components={animatedComponents}
                    isRtl={true}
                    isSearchable={true}
                    // closeMenuOnSelect={false}
                />
                {errors[id] && (
                    <div className="pb-3 pt-1">
                        <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                            {typeof errors[id] === "object" ? (
                                <div>- {errors[id][0]}</div>
                            ) : (
                                <div>- {errors[id]}</div>
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={`w-full h-full inset-0 bg-inner-container z-10 opacity-90 smooth ${
                        isDisabled ? "absolute" : "hidden"
                    } `}
                ></div>
            </div>
        );
    } else if (type === "radio") {
        let selectValue;

        // const optionsTexts = getOptionTexts(options);

        // console.log(data);
        return (
            <div className={`react-select__outer-container relative ${className}`}>
                <div className="w-full flex-center-both flex-col space-y-3 sci-fi">
                    <h3 className="font-h3 clr-text-primary smooth">{placeholder}</h3>
                    <div className="selector w-full">
                        {options.map((option) => (
                            <span
                                key={option.value}
                                className={`selection smooth py-2 px-3 clr-text-primary text-center h-full flex-col space-y-3 ${
                                    (
                                        multiple
                                            ? data[id].includes(option.value)
                                            : option.value == data[id]
                                    )
                                        ? "selected"
                                        : ""
                                }`}
                                data-id={option.value}
                                onClick={(e) => {
                                    onChange(e, id, data, setData, "radio", multiple, isIntValues);
                                }}
                            >
                                <div className="font-w-bold">{option.text}</div>
                                {option.extraData ? (
                                    <div className="flex-col flex-center-both text-center space-y-1">
                                        {option.extraData?.map((extra, index) => (
                                            <div key={index} className="font-small">
                                                {extra.value}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </span>
                        ))}
                        {/* <div className="selection smooth py-2 px-3 clr-text-primary ">اخفاء</div>
                        <div className="selection smooth py-2 px-3 clr-text-primary ">
                            اظهارها فقط لو نجح
                        </div>
                        <div className="selection smooth py-2 px-3 clr-text-primary selected">
                            اظهار دائمًا
                        </div> */}
                    </div>
                </div>
                {errors[id] && (
                    <div className="pb-3 pt-1">
                        <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                            {typeof errors[id] === "object" ? (
                                <div>- {errors[id][0]}</div>
                            ) : (
                                <div>- {errors[id]}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    } else if (type === "audio") {
        const handleAudioSave = (blob) => {
            const newAudioFile = new File([blob], `${id}.webm`, { type: "audio/webm" });
            onChange(
                { currentTarget: { value: newAudioFile, id, type: "audio" } },
                id,
                data,
                setData
            );
            setAudioURL(URL.createObjectURL(blob));
        };

        const handleDeleteAudio = () => {
            setAudioURL("");
            setData((prevData) => ({ ...prevData, [id]: null }));
        };

        return (
            <>
                <div
                    className={`flex items-center rounded-md p-2 bg-slate-300 dark:bg-slate-700 smooth gap-1 ${className} ${
                        visible ? "" : "hidden"
                    }`}
                >
                    {/* <span>{placeholder}</span> */}
                    <div
                        className={`form-reg__group ${errors[id] && "error"} ${
                            isDisabled && "disabled"
                        } `}
                        {...props}
                    >
                        <AudioRecorder
                            onRecordingComplete={handleAudioSave}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }}
                            onNotAllowedOrFound={(err) => console.error("Recording error:", err)}
                            downloadOnSavePress={false}
                            downloadFileExtension="webm"
                            mediaRecorderOptions={{
                                audioBitsPerSecond: 128000,
                            }}
                        />
                        <span className="bg"></span>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                    </div>
                    {audioURL && (
                        <div className="flex w-full items-center space-x-reverse space-x-2 p-3 bg-gray-100 dark:bg-gray-500 smooth rounded-lg shadow-md max-w-xs mx-auto">
                            <audio
                                controls
                                src={audioURL}
                                className="w-full h-8 rounded-md outline-none"
                                style={{
                                    accentColor: "#10B981",
                                }}
                            />
                            <button
                                onClick={handleDeleteAudio}
                                className="text-red-500 smooth hover:text-red-800 p-1 rounded-full bg-gray-300 dark:bg-gray-700"
                            >
                                <CenterIcon icon={"weui:delete-on-filled"} className="w-5 h-5" />
                            </button>
                        </div>
                    )}

                    {errors[id] && (
                        <div className="pb-3 pt-1">
                            <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                                {typeof errors[id] === "object" ? (
                                    <div>- {errors[id][0]}</div>
                                ) : (
                                    <div>- {errors[id]}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    } else if (type === "custom_audio") {
        const handleAudioSave = (blob) => {
            const newAudioFile = new File([blob], `${id}.webm`, { type: "audio/webm" });
            onChange(
                { currentTarget: { value: newAudioFile, id, type: "audio" } },
                id,
                data,
                setData
            );
            setAudioURL(URL.createObjectURL(blob));
        };
        return (
            <div>
                <CustomAudioRecorder
                    handleToggleRecording={handleToggleRecording}
                    onRecordingComplete={handleAudioSave}
                />
            </div>
        );
    }
    return (
        <>
            <div className={`${className}`}>
                <div
                    className={`form-reg__group ${errors[id] && "error"} ${
                        isDisabled && "disabled"
                    } `}
                    {...props}
                >
                    <input
                        ref={inputRef}
                        className="smooth z-10"
                        onChange={!isDisabled ? (e) => onChange(e, id, data, setData) : null}
                        type={type}
                        required={isRequired}
                        id={id}
                        value={data[id] ?? ""}
                        disabled={isDisabled}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                    />
                    <span className="bg"></span>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    {!isDisabled && (
                        <label>
                            <FlexRowReverse>
                                {icon}
                                <span>{placeholder}</span>
                            </FlexRowReverse>
                        </label>
                    )}
                </div>
                {errors[id] && (
                    <div className="pb-3 pt-1">
                        <div className="font-small text-white bg-rose-500 bg-opacity-70 rounded-md px-3 py-2">
                            {typeof errors[id] === "object" ? (
                                <div>- {errors[id][0]}</div>
                            ) : (
                                <div>- {errors[id]}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default InputField;
