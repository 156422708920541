import React, { useContext, useRef, useState } from "react";
import CenterIcon from "../components/ui/CenterIcon";
import { description, printRelativeDate } from "../utils/ar";
import { baseURL } from "../config";
import { showPicture } from "../services/contentServices";
import LoadingIcon from "../components/ui/LoadingIcon";
import auth from "../services/authServices";
import http from "../services/httpServices";
import AuthContext from "../context/AuthContext";
import Button from "../components/ui/Button";
import managerIcon from "../assets/manager.svg";
import { adminPath, isWhatsAppAudioPlayerStyle } from "../services/defaultSettings";
import AudioPlayer from "../components/ui/AudioPlayer";
import { Icon } from "@iconify/react/dist/iconify";

const CommunityCommentCard = ({
    comment,
    posts = [],
    post = {},
    category = {},
    setPosts = () => {},
    isPreviewOnly = false,
}) => {
    const imageSrcRef = useRef();
    const { adminToken } = useContext(AuthContext);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [isAudioLoading, setIsAudioLoading] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const handlePostAction = async (action = "delete") => {
        const token = auth.getAdminToken();
        const config = auth.getAuthConfig(token);
        setIsLoading(true);

        try {
            if (action === "delete") {
                const { data } = await http.delete(
                    `/api/community/community_categories/${category.id}/posts/${post.id}/comments/${comment.id}`,
                    config
                );
                let newComments = post.community_comments.filter(
                    (value) => value.id !== comment.id
                );

                let newPosts = posts.map((value) => {
                    if (value.id !== post.id) {
                        return value;
                    } else {
                        return {
                            ...post,
                            community_comments: newComments,
                        };
                    }
                });

                setPosts([...newPosts]);
            } else if (action === "pin_post") {
                const { data } = await http.post(
                    `/api/community/community_categories/${category.id}/posts/${post.id}/comments/${comment.id}/pin`,
                    {},
                    config
                );

                let newComments = post.community_comments.map((value) => {
                    if (value.id === comment.id) {
                        let newValue = {
                            ...value,
                            is_pinned: 1,
                        };
                        return newValue;
                    } else {
                        return value;
                    }
                });

                let newPosts = posts.map((value) => {
                    if (value.id !== post.id) {
                        return value;
                    } else {
                        return {
                            ...post,
                            community_comments: newComments,
                        };
                    }
                });
                setPosts(newPosts);
            } else if (action === "unpin_post") {
                const { data } = await http.post(
                    `/api/community/community_categories/${category.id}/posts/${post.id}/comments/${comment.id}/unpin`,
                    {},
                    config
                );

                let newComments = post.community_comments.map((value) => {
                    if (value.id === comment.id) {
                        let newValue = {
                            ...value,
                            is_pinned: 0,
                        };
                        return newValue;
                    } else {
                        return value;
                    }
                });

                let newPosts = posts.map((value) => {
                    if (value.id !== post.id) {
                        return value;
                    } else {
                        return {
                            ...post,
                            community_comments: newComments,
                        };
                    }
                });
                setPosts(newPosts);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-cyan-400 bg-opacity-20 border border-cyan-500 border-opacity-20 rounded-2xl pb-2 px-4">
            <div className="flex justify-between items-center">
                <div className="flex-center-both space-x-2 py-1 space-x-reverse">
                    {adminPath ? (
                        <a
                            className="block sm:w-7 w-4 sm:h-7 h-4 rounded-full bg-cyan-100 dark:bg-cyan-700 flex-center-both smooth"
                            href={`/${adminPath}/panel/user_profile/${comment.user_id}`}
                        >
                            {comment.created_by_admin_id ? (
                                <img className="h-10 w-10 rounded-full" src={managerIcon} alt="" />
                            ) : (
                                <CenterIcon
                                    className="w-full h-full text-cyan-800 dark:text-cyan-400 smooth "
                                    iconProperties={{ className: "h-3/4 w-3/4" }}
                                    icon="uim:comment"
                                />
                            )}
                        </a>
                    ) : (
                        <div className="sm:w-7 w-4 sm:h-7 h-4 rounded-full bg-cyan-100 dark:bg-cyan-700 flex-center-both smooth">
                            {comment.created_by_admin_id ? (
                                <img className="h-10 w-10 rounded-full" src={managerIcon} alt="" />
                            ) : (
                                <CenterIcon
                                    className="w-full h-full text-cyan-800 dark:text-cyan-400 smooth "
                                    iconProperties={{ className: "h-3/4 w-3/4" }}
                                    icon="uim:comment"
                                />
                            )}
                        </div>
                    )}
                    {/* <div className="">{post.user.full_name}</div> */}
                    <div className="clr-text-primary smooth">
                        <div className="font-w-bold font-smaller flex items-center space-x-1 space-x-reverse">
                            {comment.created_by_admin_id ? (
                                <span className="text-cyan-500">
                                    <CenterIcon nY="0" icon="teenyicons:shield-tick-solid" />{" "}
                                </span>
                            ) : (
                                ""
                            )}
                            <span>{comment.user.full_name}</span>
                        </div>
                        <div className="clr-text-secondary smooth font-smaller  flex space-x-1 space-x-reverse">
                            <span>{printRelativeDate(comment.created_at)}</span>
                        </div>
                    </div>
                </div>

                {!adminToken && comment.is_pinned ? (
                    <div className="flex-center-both flex-row font-smaller space-x-2 space-x-reverse">
                        <div className="rounded-full bg-yellow-500 py-2 px-2 font-h3">
                            <CenterIcon icon={"solar:pin-outline"} />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {!isPreviewOnly && adminToken ? (
                    <div className="flex space-x-1 space-x-reverse">
                        {isPreviewOnly ? (
                            ""
                        ) : comment.is_pinned ? (
                            <>
                                <div className="flex-center-both flex-row font-smaller space-x-2 space-x-reverse">
                                    <div className="rounded-full bg-yellow-500 py-2 px-2 font-h3">
                                        <CenterIcon icon={"solar:pin-outline"} />
                                    </div>
                                </div>
                                <Button
                                    color="rose"
                                    className="py-1 px-2"
                                    onClick={() => handlePostAction("unpin_post")}
                                    isLoading={isLoading}
                                >
                                    الغاء تثبيت التعليق
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    color="cyan"
                                    className="py-1 px-2"
                                    onClick={() => handlePostAction("pin_post")}
                                    isLoading={isLoading}
                                >
                                    تثبيت التعليق
                                </Button>
                            </>
                        )}
                        <div className="flex-center-both font-smaller">
                            <Button
                                color="rose"
                                className="px-2 py-1"
                                onClick={() => handlePostAction("delete")}
                                isLoading={isLoading}
                            >
                                حذف
                            </Button>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {comment.created_by_admin_id ? (
                <div className="flex-center-both mb-2">
                    <div className="font-smaller text-center bg-cyan-900 dark:bg-cyan-100 text-white dark:text-slate-900 smooth py-2 px-5 rounded-md flex space-x-1 space-x-reverse flex-center-both">
                        <span className="font-h2">
                            <CenterIcon nY="0" icon="mdi:shield-tick" />
                        </span>
                        <span>تم انشاء هذا التعليق بواسطه المسئولين عن المنصه</span>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className="px-5">
                <div className="h-0.5 bg-cyan-700 bg-opacity-20 w-full rounded-md smooth"></div>
            </div>
            <div className="pt-1 sm:px-2 px-5 clr-text-primary smooth">
                {description(comment.description, true)}
            </div>
            {comment.image_source ? (
                <div className="px-2">
                    <div className="w-full rounded-md relative overflow-hidden text-center h-[150px] flex-center-both">
                        <img
                            src={`${baseURL}/${comment.image_source}`}
                            alt="course"
                            ref={imageSrcRef}
                            onLoad={() => {
                                setIsImageLoading(false);
                            }}
                            className={` ${
                                isImageLoading ? "hidden " : ""
                            } h-full max-w-full cursor-pointer rounded-md `}
                            onClick={() => showPicture(imageSrcRef.current.src, false, true)}
                        />

                        {isImageLoading ? (
                            <div className="h-full bg-slate-300 dark:bg-slate-50/10 flex-center-both font-h1 font-w-bold">
                                <div className="flex flex-row space-x-3 space-x-reverse">
                                    <LoadingIcon className={"font-big text-blue-500"} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                ""
            )}
            {comment.audio_source && (
                <div className="px-2 mt-2">
                    <div
                        className={`w-full rounded-md ${
                            !isWhatsAppAudioPlayerStyle && "bg-gray-200 dark:bg-gray-800"
                        }  p-2 flex items-center justify-between`}
                    >
                        {isAudioLoading && (
                            <div className="flex items-center justify-center w-full h-full">
                                <LoadingIcon className="text-blue-500" />
                            </div>
                        )}
                        {isWhatsAppAudioPlayerStyle ? (
                            <AudioPlayer
                                isDeletingAllowed={false}
                                audioSrc={`${baseURL}/${comment.audio_source}`}
                                onLoadedData={() => setIsAudioLoading(false)}
                            />
                        ) : (
                            <audio
                                controls
                                src={`${baseURL}/${comment.audio_source}`}
                                className={`w-full max-w-full ${isAudioLoading ? "hidden" : ""}`}
                                onCanPlayThrough={() => setIsAudioLoading(false)}
                            ></audio>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommunityCommentCard;
